import { useEffect, useReducer, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Formik } from 'formik'

import { colors } from '../../constants/colors'
import { Link } from '../../elements/Link'
import { ProgressButton } from '../../elements/ProgressButton/ProgressButton'
import { themeToPresetsMap } from '../../elements/ProgressButton/ProgressButton'
import { Type } from '../../elements/Typography/Typography'
import { ErrorMessage } from '../../form/ErrorMessage/ErrorMessage'
import { ErrorFocus } from './ErrorFocus'
import FormSuccess from './FormSuccess'
import * as styles from './styles'
import { widgets } from './widgets'

const actionTypes = {
  IS_SUBMITTING: 'submitting',
  IS_FINISHED: 'isFinished',
  SET_ERROR: 'setError',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.IS_SUBMITTING:
      return {
        ...state,
        submitting: action.submitting,
      }
    case actionTypes.IS_FINISHED:
      return {
        ...state,
        submitting: false,
        isFinished: true,
      }
    case actionTypes.SET_ERROR:
      return {
        ...state,
        serverError: action.error,
      }
  }
}

export const Form = ({
  form,
  onSubmit,
  validate,
  onSuccess,
  recaptchaKey,
  buttonText = 'Submit',
  formSuccess,
  withTopPadding = true,
  initialValues = {},
  hideCaptcha = false,
  fullWidthSubmitButton = false,
  theme = 'default',
}) => {
  const [{ submitting, isFinished, serverError }, dispatch] = useReducer(
    reducer,
    {
      isFinished: false,
      submitting: false,
      serverError: null,
    }
  )

  const reCaptcha = useRef(null)
  const savedValues = useRef({})
  const [hasRequired, setHasRequired] = useState(false)

  useEffect(() => {
    setHasRequired(form.fields.some(field => field.isRequired))
  }, [setHasRequired, form])

  const submitWithCaptcha = async (values, reCaptchaValue) => {
    if (submitting) {
      return
    }

    dispatch({ type: actionTypes.IS_SUBMITTING, submitting: true })

    try {
      const shouldContinue = await onSubmit({
        ...values,
        __recaptcha: reCaptchaValue,
      })

      if (!shouldContinue) {
        dispatch({ type: actionTypes.IS_SUBMITTING, submitting: false })
        return
      }

      dispatch({ type: actionTypes.IS_FINISHED })
      setTimeout(() => {
        if (onSuccess) onSuccess()
        dispatch({ type: actionTypes.IS_SUBMITTING, submitting: false })
      }, 300)
    } catch (serverError) {
      // Need to get a new recaptcha token when we try again.
      reCaptcha.current.reset()
      dispatch({ type: actionTypes.SET_ERROR, error: serverError })
    }

    dispatch({ type: actionTypes.IS_SUBMITTING, submitting: false })
  }

  const submit = values => {
    savedValues.current = values
    const reCaptchaValue = reCaptcha.current.getValue()
    if (reCaptchaValue) {
      submitWithCaptcha(values, reCaptchaValue)
    } else {
      reCaptcha.current.execute()
    }
  }

  const onFinishCaptcha = reCaptchaValue => {
    if (reCaptchaValue) {
      submitWithCaptcha(savedValues.current, reCaptchaValue)
    }
  }

  if (isFinished && formSuccess) {
    return <FormSuccess formSuccess={formSuccess} />
  }

  return (
    <Formik
      onSubmit={submit}
      validate={validate}
      initialValues={initialValues}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        submitCount,
        errors,
        touched,
        isSubmitting,
        isValidating,
        values,
      }) => (
        <styles.Form onSubmit={handleSubmit} withTopPadding={withTopPadding}>
          <ErrorMessage
            error={!!serverError}
            errorMessage={serverError?.message}
          />
          {form.fields.map(field => {
            const Widget = widgets[field.type]

            return Widget ? (
              <Widget
                key={field.key}
                field={field}
                hasTriedSubmitting={submitCount > 0}
                errors={errors}
                touched={touched}
                isHidden={field.isHidden}
                values={values}
              />
            ) : null
          })}
          {hideCaptcha && (
            <styles.CaptchaTextWrapper>
              <Type preset="tiny" inline lineHeight={0}>
                This site is protected by reCAPTCHA and the Google
                <Link
                  to="https://policies.google.com/privacy"
                  style={{ color: colors.deepBlue }}
                >
                  {' '}
                  Privacy Policy
                </Link>
                {' and '}
                <Link
                  to="https://policies.google.com/terms"
                  style={{ color: colors.deepBlue }}
                >
                  Terms of Service
                </Link>
                {' apply'}
              </Type>
            </styles.CaptchaTextWrapper>
          )}
          <styles.FormBottom
            style={fullWidthSubmitButton ? { flexDirection: 'column' } : {}}
          >
            <styles.CaptchaWrapper hide={hideCaptcha}>
              <ReCAPTCHA
                ref={reCaptcha}
                sitekey={recaptchaKey}
                size="invisible"
                badge="inline"
                onChange={onFinishCaptcha}
              />
            </styles.CaptchaWrapper>
            <styles.SubmitButton
              style={fullWidthSubmitButton ? { width: '100%', margin: 0 } : {}}
            >
              <ProgressButton
                type="submit"
                loading={submitting}
                complete={isFinished}
                display="block"
                paddingSize="large"
                weight="bold"
                size={{ xs: 14, md: 16 }}
                style={{ width: '100%' }}
                preset={themeToPresetsMap[theme]}
              >
                {buttonText}
              </ProgressButton>
            </styles.SubmitButton>
            {hasRequired && (
              <Type
                style={{ flex: '1 1 100%' }}
                size={{ xs: 12, md: 14 }}
                top={{ xs: 2, md: 2 }}
                inline
              >
                * required field
              </Type>
            )}
          </styles.FormBottom>
          <ErrorFocus
            isSubmitting={isSubmitting}
            isValidating={isValidating}
            errors={errors}
          />
        </styles.Form>
      )}
    </Formik>
  )
}
